import { Button, Grid } from '@material-ui/core';
import { RequestHeaderButtonWithDialogInj, useStore } from '@platform/ics-front-core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRequestExecutorsContext } from '../../../../../hooks/contexts/useRequestExecutorsContext';
import { useRequestHeaderContext } from '../../../../../hooks/contexts/useRequestHeaderContext';
import { getExecutorLocales, isSpecialStateCode } from '../../../../../utils/campaignRequest';

export type RequestExecutorsActionsProps = {
    isExecutorExist: boolean;
};

export const RequestExecutorsActions = observer((props: RequestExecutorsActionsProps): JSX.Element => {
    const { isExecutorExist } = props;
    const { addActualAppointment, updateActualAppointment } = useRequestExecutorsContext();
    const { requestModel, reloadIncrement, onModalOpen } = useRequestHeaderContext();
    const { requestStore, intlStore: intl } = useStore();
    const { id, state } = requestModel;
    const { buttonTitle, chooseDialogTitle } = getExecutorLocales(state.code);

    const handleConfirm = (): void => {
        requestStore.setCurrentUserAsExecutor(id).then(() => {
            requestModel.updateExecutors();
            reloadIncrement();
        });
    };

    const handleAppoint = (): void => {
        onModalOpen(chooseDialogTitle);
    };

    const isWithAppointButton =
        (addActualAppointment || isSpecialStateCode(state.code)) && updateActualAppointment && !isExecutorExist;

    return (
        <Grid item container wrap="nowrap" spacing={1}>
            {addActualAppointment && (
                <Grid item>
                    <RequestHeaderButtonWithDialogInj
                        title={intl.formatMessage('campaignRequest.dialog.executorsTitle')}
                        message={intl.formatMessage('campaignRequest.dialog.executorsMessage')}
                        id="takeToWork"
                        onConfirm={handleConfirm}
                    />
                </Grid>
            )}

            {isWithAppointButton && (
                <Grid item>
                    <Button onClick={handleAppoint} color="secondary" size="small" variant="contained">
                        <FormattedMessage id={buttonTitle} />
                    </Button>
                </Grid>
            )}
        </Grid>
    );
});
