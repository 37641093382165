import { AuthorizationCheckQuery } from '@platform/ics-front-core';
import { entities, permissions } from '../authSchemeConfig';
import { RequestModel } from '../di/models/RequestModel';
import { SpecialExecutorType, SpecialStateCode } from '../types/campaignRequest';

export const isSpecialStateCode = (stateCode: string): stateCode is SpecialStateCode => {
    return [
        SpecialStateCode.AllocationToCommittees,
        SpecialStateCode.AllocationToWorkGroup,
        SpecialStateCode.DepartmentDetermination,
        SpecialStateCode.InitialReviewByCommittee,
    ].includes(stateCode as SpecialStateCode);
};

export const getSpecialExecutorType = (stateCode: SpecialStateCode): SpecialExecutorType => {
    switch (stateCode) {
        case SpecialStateCode.DepartmentDetermination:
            return SpecialExecutorType.ROP;
        case SpecialStateCode.AllocationToCommittees:
            return SpecialExecutorType.MemberCommittee;
        case SpecialStateCode.InitialReviewByCommittee:
            return SpecialExecutorType.ModeratorRG;
        case SpecialStateCode.AllocationToWorkGroup:
            return SpecialExecutorType.MemberRG;
    }
};

type ExecutorLocales = {
    singleTitle: string;
    buttonTitle: string;
    chooseDialogTitle?: string;
    changeDialogTitle?: string;
};

export const getExecutorLocales = (stateCode?: SpecialStateCode | (string & {})): ExecutorLocales => {
    switch (stateCode) {
        case SpecialStateCode.DepartmentDetermination:
            return {
                singleTitle: 'campaignRequest.executorROP.single',
                buttonTitle: 'campaignRequest.executorROP.choose',
                chooseDialogTitle: 'campaignRequest.executorROP.choose',
                changeDialogTitle: 'campaignRequest.executorROP.change',
            };
        case SpecialStateCode.AllocationToCommittees:
            return {
                singleTitle: 'campaignRequest.executorCommittee.single',
                buttonTitle: 'campaignRequest.executorCommittee.choose',
                chooseDialogTitle: 'campaignRequest.executorCommittee.choose',
                changeDialogTitle: 'campaignRequest.executorCommittee.change',
            };
        case SpecialStateCode.InitialReviewByCommittee:
            return {
                singleTitle: 'campaignRequest.executorModeratorRG.single',
                buttonTitle: 'campaignRequest.executorModeratorRG.choose',
                chooseDialogTitle: 'campaignRequest.executorModeratorRG.choose',
                changeDialogTitle: 'campaignRequest.executorModeratorRG.change',
            };
        case SpecialStateCode.AllocationToWorkGroup:
            return {
                singleTitle: 'campaignRequest.executorRG.single',
                buttonTitle: 'campaignRequest.executorRG.choose',
                chooseDialogTitle: 'campaignRequest.executorRG.choose',
                changeDialogTitle: 'campaignRequest.executorRG.change',
            };
        default:
            return {
                singleTitle: 'campaign.campaignRegFormExecutorResponsibleExecutor',
                buttonTitle: 'campaignRequest.chooseExecutor',
                chooseDialogTitle: 'campaignRequest.dialog.editExecutorTitle',
                changeDialogTitle: 'campaignRequest.dialog.changeExecutorTitle',
            };
    }
};

export const getIsExecutorExist = (requestModel: RequestModel): boolean | undefined => {
    const { state, specialExecutors } = requestModel;
    const stateCode = state.code as SpecialStateCode | (string & {});
    const { ROP, MemberCommittee, ModeratorRG, MemberRG } = specialExecutors;

    switch (stateCode) {
        case SpecialStateCode.DepartmentDetermination:
            return !!ROP;
        case SpecialStateCode.AllocationToCommittees:
            return !!MemberCommittee;
        case SpecialStateCode.InitialReviewByCommittee:
            return !!ModeratorRG;
        case SpecialStateCode.AllocationToWorkGroup:
            return !!MemberRG;
    }
};

export const getExecutorQueries = (
    id: string,
    stateCode: SpecialStateCode | (string & {}),
): AuthorizationCheckQuery[] => {
    switch (stateCode) {
        case SpecialStateCode.DepartmentDetermination:
            return [
                {
                    entityCode: entities.CampaignRequest,
                    entityId: id,
                    permCode: permissions.CampaignRequest.UpdateROPAppointment,
                },
            ];
        case SpecialStateCode.AllocationToCommittees:
            return [
                {
                    entityCode: entities.CampaignRequest,
                    entityId: id,
                    permCode: permissions.CampaignRequest.UpdateMemberCommitteeAppointment,
                },
            ];
        case SpecialStateCode.InitialReviewByCommittee:
            return [
                {
                    entityCode: entities.CampaignRequest,
                    entityId: id,
                    permCode: permissions.CampaignRequest.UpdateModeratorRGAppointment,
                },
            ];
        case SpecialStateCode.AllocationToWorkGroup:
            return [
                {
                    entityCode: entities.CampaignRequest,
                    entityId: id,
                    permCode: permissions.CampaignRequest.UpdateMemberRGAppointment,
                },
            ];

        default:
            return [
                {
                    entityCode: entities.CampaignRequest,
                    entityId: id,
                    permCode: permissions.CampaignRequest.UpdateActualAppointment,
                },
                {
                    entityCode: entities.CampaignRequest,
                    entityId: id,
                    permCode: permissions.CampaignRequest.AddActualAppointment,
                },
            ];
    }
};
