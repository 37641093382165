import { Box, Container, Grid, Link, Tooltip, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
    AdditionalTitleLinkInj,
    AuthorizationCheck,
    clientRoute,
    entities,
    permissions,
    RequestDeadline,
    RequestHeader as RequestHeaderInj,
    RequestHeaderProps,
    RequestStateBoxInj,
    TotObjectHeader,
} from '@platform/ics-front-core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectable } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { RequestHeaderContext, RequestHeaderContextType } from '../../../../contexts/RequestHeaderContext';
import { RequestModel } from '../../../models/RequestModel';
import { RequestExecutors } from './campaign-request-executors/RequestExecutors';

export const RequestHeader = observer((props: RequestHeaderProps): JSX.Element => {
    const { requestModel: requestModelCore, onModalOpen, reloadIncrement } = props;
    const theme = useTheme();
    const requestModel = requestModelCore as RequestModel;
    const { number, campaign, regFormTitle, state, id, deadlines } = requestModel;
    const { stateDeadline, entityDeadline } = deadlines;

    const contextValue: RequestHeaderContextType = {
        requestModel,
        onModalOpen,
        reloadIncrement,
    };

    return (
        <RequestHeaderContext.Provider value={contextValue}>
            <TotObjectHeader>
                <Container maxWidth="lg">
                    <Grid container spacing={10} alignItems="center">
                        <Grid item xs={8}>
                            <Grid container spacing={3} direction="column">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="campaignRequest.RequestN" values={{ number }} />
                                    </Typography>
                                </Grid>
                                <AdditionalTitleLinkInj requestId={id} />
                                <Grid item>
                                    {campaign.id && (
                                        <Link
                                            component={NavLink}
                                            underline="none"
                                            to={generatePath(clientRoute.campaign, {
                                                id: campaign.id,
                                            })}
                                        >
                                            <Typography variant="h3">{campaign.title}</Typography>
                                        </Link>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Box color={theme?.variables.palette.textMain}>
                                        <Typography variant="body2">{regFormTitle}</Typography>
                                    </Box>
                                </Grid>
                                <RequestDeadline
                                    deadline={entityDeadline}
                                    title={<FormattedMessage id="campaignRequest.deadlines.entity" />}
                                    withBlock={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid
                                container
                                spacing={3}
                                direction="column"
                                wrap="nowrap"
                                justify="center"
                                style={{ height: '100%' }}
                            >
                                <Grid item>
                                    <Box>
                                        <Typography variant="body2">
                                            <FormattedMessage id="common.State" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <RequestStateBoxInj>
                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                                    {state.title}
                                                </Typography>
                                            </RequestStateBoxInj>
                                        </Grid>
                                        <Grid item>
                                            <AuthorizationCheck
                                                entityCode={entities.System}
                                                entityId={id}
                                                permCode={permissions.System.ViewLogsRegistry}
                                            >
                                                <Tooltip title={<FormattedMessage id="campaignRequest.requestLog" />}>
                                                    <Link
                                                        component={NavLink}
                                                        underline="none"
                                                        to={generatePath(clientRoute.requestLog, { id })}
                                                    >
                                                        <AssignmentIcon />
                                                    </Link>
                                                </Tooltip>
                                            </AuthorizationCheck>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <RequestDeadline
                                    deadline={stateDeadline}
                                    title={<FormattedMessage id="campaignRequest.deadlines.state" />}
                                />
                                <RequestExecutors />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectHeader>
        </RequestHeaderContext.Provider>
    );
});

export const RequestHeaderDI = injectable(RequestHeaderInj, RequestHeader);
