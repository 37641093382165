const campaignRequest: Record<string, Record<string, string> | string> = {
    executorROP: {
        choose: 'Appoint a ROP Executor',
        change: 'Change a ROP Executor',
        single: 'ROP Executor',
    },
    executorCommittee: {
        choose: 'Appoint a Committee Executor',
        change: 'Change a Committee Executor',
        single: 'Committee Executor',
    },
    executorModeratorRG: {
        choose: 'Appoint a Moderator RG Executor',
        change: 'Change a Moderator RG Executor',
        single: 'Moderator RG Executor',
    },
    executorRG: {
        choose: 'Appoint a RG Executor',
        change: 'Change a RG Executor',
        single: 'RG Executor',
    },
    executorsCount: 'Executors ({count})',
};

export default campaignRequest;
