const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Инициативы',
    RequestN: 'Инициатива № {number}',
    registryColumns: {
        number: 'Номер инициативы',
        campaign: 'Направление',
        regForm: 'Форма инициативы',
    },
    registryPagingInfo:
        'Всего {count, number} инициатив{count, plural, one {а} few {ы} other {}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить инициативу № {number}?',
    readonlyRequestValidationMsg:
        'Форма инициативы заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    printFormsTable: {
        crcCode: 'CRC-код',
        cannotGenerateError: 'Невозможно сгенерировать печатную форму: форма инициативы заполнена некорректно',
        cannotGenerateErrorWithLink:
            'Невозможно сгенерировать печатную форму: форма инициативы заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    },
    regFormsPageTitle: 'Выбор формы инициативы',
    createRequest: 'Направить инициативу',
    newRequest: 'Новая инициатива',
    dialog: {
        leaveMessage: 'Вы действительно хотите покинуть текущую инициативу без сохранения изменений?',
        executorsMessage: 'Вы будете назначены ответственным исполнителем по инициативе',
    },
    registryPagingInfoIfNull: 'Всего 0 инициатив. Отображено 0 инициатив',
    deleteRequest: 'Удалить инициативу',
    actions: {
        requestToWork: 'Взять инициативу в работу',
    },
    requestLog: 'Журнал инициативы',
    errorTextForRequest:
        'Проверьте заполненность обязательных полей инициативы и корректность введенных значений. Разделы, в которых содержатся ошибки, отмечены красным восклицательным знаком справа от анкеты инициативы',
    executorROP: {
        choose: 'Назначить ответственного Руководителя ОП',
        change: 'Изменить ответственного Руководителя ОП',
        single: 'Ответственный Руководитель ОП',
    },
    executorCommittee: {
        choose: 'Назначить ответственный комитет',
        change: 'Изменить ответственный комитет',
        single: 'Ответственный комитет',
    },
    executorModeratorRG: {
        choose: 'Назначить ответственного Модератора РГ',
        change: 'Изменить ответственного Модератора РГ',
        single: 'Ответственный Модератор РГ',
    },
    executorRG: {
        choose: 'Назначить ответственную РГ',
        change: 'Изменить ответственную РГ',
        single: 'Ответственная РГ',
    },
    executorsCount: 'Ответственные ({count})',
};

export default campaignRequest;
