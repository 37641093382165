import ru from '../../common/ru';
import { recordToLang } from '../../utils';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import common from './common';
import homePage from './homePage';
import { requestLog } from './requestLog';
import startPage from './startPage';

const lang: Record<string, string> = {
    ...ru,
    ...recordToLang({
        startPage,
        homePage,
        campaign,
        campaignRequest,
        breadcrumbs,
        requestLog,
        common,
    }),
};

export default lang;
