export enum SpecialExecutorType {
    ROP = 'ROP',
    MemberCommittee = 'MemberCommittee',
    ModeratorRG = 'ModeratorRG',
    MemberRG = 'MemberRG',
}

export enum SpecialStateCode {
    DepartmentDetermination = 'DepartmentDetermination',
    AllocationToCommittees = 'AllocationToCommittees',
    InitialReviewByCommittee = 'InitialReviewByCommittee',
    AllocationToWorkGroup = 'AllocationToWorkGroup',
}
