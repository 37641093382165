import { recordToLang } from '@platform/ics-front-core';
import en from '../../common/en';
import campaignRequest from './campaignRequest';
import common from './common';

const lang: Record<string, string> = {
    ...en,
    ...recordToLang({ campaignRequest, common }),
};

export default lang;
