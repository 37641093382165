import { AxiosRequestConfig } from 'axios';
import { SpecialExecutorType } from './types/campaignRequest';

class ApiConfigs {
    generatePrintForm: (formId: string, templateId: string) => AxiosRequestConfig = (formId, templateId) => {
        return {
            method: 'POST',
            url: '/pf',
            params: {
                template: templateId,
                customForm: formId,
            },
        };
    };

    loadExecutors: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users/v2?role=Employee&role=SeniorEmployee&role=ModeratorRG',
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllActiveEmployees: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users?role=Employee&role=SeniorEmployee&role=ModeratorRG',
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllEmployees: () => AxiosRequestConfig = () => {
        return {
            url: '/select/users?role=Employee&role=SeniorEmployee&role=ModeratorRG',
            method: 'GET',
        };
    };

    getSpecialExecutorUsers: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/select/specialExecutorUsers`,
            method: 'GET',
            params: {
                request: requestId,
            },
        };
    };

    editSpecialExecutorSettings: (
        requestId: string,
        userId: string,
        executorType: SpecialExecutorType,
    ) => AxiosRequestConfig = (requestId, userId, executorType) => {
        return {
            url: `/request/${requestId}/specialExecutor`,
            method: 'PUT',
            params: {
                executorType,
            },
            data: {
                userId,
            },
        };
    };

    getSpecialExecutorSettings: (requestId: string, executorType: SpecialExecutorType) => AxiosRequestConfig = (
        requestId,
        executorType,
    ) => {
        return {
            url: `/request/${requestId}/specialExecutor`,
            method: 'GET',
            params: {
                executorType,
            },
        };
    };
}

export default new ApiConfigs();
