import { Button, Collapse, Grid } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useStore } from '@platform/ics-front-core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestExecutorsContext, RequestExecutorsContextType } from '../../../../../contexts/RequestExecutorsContext';
import { useRequestHeaderContext } from '../../../../../hooks/contexts/useRequestHeaderContext';
import { SpecialStateCode } from '../../../../../types/campaignRequest';
import { getExecutorQueries, getIsExecutorExist } from '../../../../../utils/campaignRequest';
import { RequestExecutor } from './RequestExecutor';
import { RequestExecutorsActions } from './RequestExecutorsActions';

export const RequestExecutors = observer((): JSX.Element => {
    const { requestModel } = useRequestHeaderContext();
    const [checkedPermissions, setCheckedPermissions] = useState<boolean[]>([]);
    const [isExecutorCollapsed, setExecutorsCollapsed] = useState(false);
    const { authorizationStore } = useStore();

    const { specialExecutors, currentTab, id, state, allExecutorsCount } = requestModel;
    const { ROP, MemberCommittee, ModeratorRG, MemberRG } = specialExecutors;
    const collapseButtonIcon = isExecutorCollapsed ? <ExpandLess /> : <ExpandMore />;
    const [updateActualAppointment, addActualAppointment] = checkedPermissions;
    const currentTabExecutor = currentTab?.tabExecutor;

    const tabExecutor = useMemo(() => {
        if (currentTabExecutor) {
            return currentTabExecutor;
        } else if (requestModel.executorsList) {
            return requestModel.executorsList;
        }
        return '';
    }, [currentTabExecutor, requestModel.executorsList]);

    useEffect(() => {
        authorizationStore.checkAll(getExecutorQueries(id, state.code)).then(setCheckedPermissions);
    }, [authorizationStore, setCheckedPermissions, id, currentTabExecutor, requestModel.executors, state.code]);

    const toggleCollapsed = (): void => {
        setExecutorsCollapsed((x) => !x);
    };

    const isExecutorExist = getIsExecutorExist(requestModel) ?? !!tabExecutor;

    const contextValue: RequestExecutorsContextType = {
        updateActualAppointment,
        addActualAppointment,
    };

    return (
        <RequestExecutorsContext.Provider value={contextValue}>
            {!!allExecutorsCount && (
                <Grid item>
                    <Button variant="contained" startIcon={collapseButtonIcon} onClick={toggleCollapsed}>
                        <FormattedMessage id="campaignRequest.executorsCount" values={{ count: allExecutorsCount }} />
                    </Button>
                    <Collapse in={isExecutorCollapsed}>
                        {ROP && (
                            <RequestExecutor
                                executor={ROP.person}
                                executorStateCode={SpecialStateCode.DepartmentDetermination}
                            />
                        )}
                        {tabExecutor && <RequestExecutor executorName={tabExecutor} />}
                        {MemberCommittee && (
                            <RequestExecutor
                                executor={MemberCommittee.person}
                                executorStateCode={SpecialStateCode.AllocationToCommittees}
                            />
                        )}
                        {ModeratorRG && (
                            <RequestExecutor
                                executor={ModeratorRG.person}
                                executorStateCode={SpecialStateCode.InitialReviewByCommittee}
                            />
                        )}
                        {MemberRG && (
                            <RequestExecutor
                                executor={MemberRG.person}
                                executorStateCode={SpecialStateCode.AllocationToWorkGroup}
                            />
                        )}
                    </Collapse>
                </Grid>
            )}
            <RequestExecutorsActions isExecutorExist={isExecutorExist} />
        </RequestExecutorsContext.Provider>
    );
});
