import { RequestModel as RequestModelInj } from '@platform/ics-front-core';
import { action, computed, observable } from 'mobx';
import { injectable } from 'react-magnetic-di';
import { RequestDTO } from '../store/RequestStore';

export class RequestModel extends RequestModelInj {
    @observable specialExecutors: RequestDTO['specialExecutors'] = {};

    @computed
    get allExecutorsCount(): number {
        return this.executors.length + Object.keys(this.specialExecutors).length;
    }

    @action.bound
    load(dto: RequestDTO): void {
        this.specialExecutors = dto.specialExecutors;
        super.load(dto);
    }

    @action.bound
    updateExecutors(): void {
        this.requestStore.getRequestDTO(this.id).then((data) => {
            const request = data as RequestDTO;
            this.executors = request.executors;
            this.specialExecutors = request.specialExecutors;
            this.formsTabs = request.customTabs;
            this.updateCurrentTab();
        });
    }
}

export const RequestModelDI = injectable(RequestModelInj, RequestModel);
