import { Grid, Typography } from '@material-ui/core';
import { CampaignEditButton, PersonDTO } from '@platform/ics-front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRequestExecutorsContext } from '../../../../../hooks/contexts/useRequestExecutorsContext';
import { useRequestHeaderContext } from '../../../../../hooks/contexts/useRequestHeaderContext';
import { SpecialStateCode } from '../../../../../types/campaignRequest';
import { getExecutorLocales, isSpecialStateCode } from '../../../../../utils/campaignRequest';
import { getPersonFIO } from '../../../../../utils/person';

export type RequestExecutorProps = {
    executorStateCode?: SpecialStateCode | (string & {});
} & (
    | {
          executor: PersonDTO;
          executorName?: never;
      }
    | {
          executor?: never;
          executorName: string;
      }
);

export const RequestExecutor = observer((props: RequestExecutorProps): JSX.Element => {
    const { executor, executorName = executor && getPersonFIO(executor), executorStateCode } = props;

    const { updateActualAppointment } = useRequestExecutorsContext();
    const { onModalOpen, requestModel } = useRequestHeaderContext();
    const { state } = requestModel;
    const stateCode = state.code as SpecialStateCode | (string & {});
    const isWithEditButton =
        updateActualAppointment &&
        (executorStateCode ? stateCode === executorStateCode : !isSpecialStateCode(stateCode));
    const { changeDialogTitle } = getExecutorLocales(stateCode);
    const { singleTitle } = getExecutorLocales(executorStateCode);

    const onEdit = (): void => {
        onModalOpen(changeDialogTitle);
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography style={{ fontWeight: 'bold' }} variant="body2" noWrap>
                    <FormattedMessage id={singleTitle} />:
                </Typography>
            </Grid>
            <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="body2">{executorName}</Typography>
                </Grid>

                {isWithEditButton && (
                    <Grid item>
                        <CampaignEditButton onEdit={onEdit} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
});
